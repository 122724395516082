export const PATCH_FS_VIDEO_LOADING = "patch/fs/video/loading"
export const PATCH_FS_VIDEO_SUCCESS = "patch/fs/video/success"
export const PATCH_FS_VIDEO_ERROR = "patch/fs/video/error"

export const POST_UPLOAD_FS_VIDEO_LOADING = "post/upload/fs/video/loading"
export const POST_UPLOAD_FS_VIDEO_ERROR = "post/upload/fs/video/error"
export const POST_UPLOAD_FS_VIDEO_SUCCESS = "post/upload/fs/video/success"

export const POST_FS_VIDEO_JOB_LOADING = "post/fs/video/job/loading"
export const POST_FS_VIDEO_JOB_SUCCESS = "post/fs/video/job/success"
export const POST_FS_VIDEO_JOB_ERROR = "post/fs/video/job/error"

export const SET_PATCH_FS_LOADING_FALSE = "set/patch/fs/loading/false"