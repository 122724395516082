import { RESET } from "../document/doc.types"
import { GET_VOICES_LIST_ERROR, GET_VOICES_LIST_LOADING, GET_VOICES_LIST_SUCCESS } from "./voices.types"

const initialState = {
    voices_data: [],
    voices_loading: false,
    voices_error: false
}


export const voicesReducer = (state = initialState, { type, payload }) => {

    switch (type) {

        case GET_VOICES_LIST_LOADING :{

            return {...state, voices_loading:true}
        }

        case GET_VOICES_LIST_SUCCESS:{

            return {...state, voices_loading:false, voices_error:false, voices_data:payload}
        }

        case GET_VOICES_LIST_ERROR:{

            return {...state, voices_error:true, voices_loading:false}
        }
        case RESET: {
            return initialState
        }

        default: {
            return state
        }
    }

}