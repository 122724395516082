import { Box } from "@chakra-ui/layout";
import "./fallback.css"

export default function FallbackLoader({scale = 1}) {
    return <Box  w={"100%"} h={"100vh"} display={"grid"} placeItems={"center"}>
        <section className="loader" style={{transform:`scale(${scale})`}}>
            {[...Array(5)].map((_, index) => (
                <div className="slider" key={index} style={{ '--i': index }}>
                </div>
            ))}
        </section>
    </Box>
}