import { SET_PAYLOAD } from "./utils.types"

const intialState = {
    payload: null,
}


export const utilsReducer = (state = intialState, { type, payload }) => {


    switch (type) {

        case SET_PAYLOAD: {
            return { ...state, payload: payload }
        }

        default: {
            return state
        }
    }

}