import { CREATE_DOC_ERROR, CREATE_DOC_LOADING, CREATE_DOC_SUCCESS, DELETE_DOCS_ERROR, DELETE_DOCS_LOADING, DELETE_DOCS_SUCCESS, GET_DOCS_ERROR, GET_DOCS_LOADING, GET_DOCS_SUCCESS, RESET } from "./doc.types"

const initialState = {
    doc_loading: false,
    data: false,
    doc_error: false,
    delete_loading:false,
    delete_error:false
}

export const docReducer = (state = initialState, { type, payload }) => {

    switch (type) {

        case CREATE_DOC_LOADING: {

            return { ...state, doc_loading: true }
        }

        case CREATE_DOC_SUCCESS: {
            return { ...state, doc_loading: false, doc_error: false, data: payload }
        }

        case CREATE_DOC_ERROR: {
            return { ...state, doc_loading: false, doc_error: true }
        }

        case GET_DOCS_LOADING: {

            return { ...state, doc_loading: true }
        }

        case GET_DOCS_SUCCESS: {
            return { ...state, doc_loading: false, doc_error: false, data: payload }
        }

        case GET_DOCS_ERROR: {
            return { ...state, doc_loading: false, doc_error: true }
        }

        case DELETE_DOCS_LOADING: {

            return { ...state, delete_loading: true }
        }

        case DELETE_DOCS_SUCCESS: {
            return { ...state, delete_loading: false, delete_error: false }
        }

        case DELETE_DOCS_ERROR: {
            return { ...state, delete_loading: false, delete_error: true }
        }

        case RESET :{

            return {...state, data:false}
        }

        default: {
            return state
        }
    }

}