export const GET_TRANS_JOBID_LOADING = "get/trans/jobid/loading"
export const GET_TRANS_JOBID_ERROR = "get/trans/jobid/error"
export const GET_TRANS_JOBID_SUCCESS = "get/trans/jobid/success"

export const GET_TRANS_JOB_STATUS_LOADING = "get/trans/job/status/loading"
export const GET_TRANS_JOB_STATUS_ERROR = "get/trans/job/status/error"
export const GET_TRANS_JOB_STATUS_SUCCESS = "get/trans/job/status/success"
export const DISSMISS_LOADER = "dissmiss/loader"
export const RESET = "reset"

export const PATCH_TRANSCRIPT_LOADING = "patch/transcript/loading"
export const PATCH_TRANSCRIPT_LOADING_FALSE = "patch/transcript/loading/false"
export const PATCH_TRANSCRIPT_SUCCESS = "patch/transcript/success"
export const PATCH_TRANSCRIPT_ERROR = "patch/transcript/error"

export const POST_TRANSCRIPT_JOB_LOADING = "post/transcript/job/loading"
export const POST_TRANSCRIPT_JOB_SUCCESS = "post/transcript/job/success"
export const POST_TRANSCRIPT_JOB_ERROR = "post/transcript/job/error"

export const SET_DOC_ID = "set/doc/id"
