export const CREATE_DOC_LOADING = "create/doc/loading"
export const CREATE_DOC_ERROR = "create/doc/error"
export const CREATE_DOC_SUCCESS = "create/doc/success"

export const GET_DOCS_LOADING = "get/docs/loading"
export const GET_DOCS_ERROR = "get/docs/error"
export const GET_DOCS_SUCCESS = "get/docs/success"

export const DELETE_DOCS_LOADING = "delete/docs/loading"
export const DELETE_DOCS_ERROR = "delete/docs/error"
export const DELETE_DOCS_SUCCESS = "delete/docs/success"

export const RESET = "reset"