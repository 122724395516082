import { RESET } from "../../document/doc.types"
import { GET_FS_VIDEO_JOB_ERROR, GET_FS_VIDEO_JOB_LOADING, GET_FS_VIDEO_JOB_SUCCESS, GET_FS_VIDEOS_CREDITS_ERROR, GET_FS_VIDEOS_CREDITS_LOADING, GET_FS_VIDEOS_CREDITS_SUCCESS, GET_FS_VIDEOS_LIST_ERROR, GET_FS_VIDEOS_LIST_LOADING, GET_FS_VIDEOS_LIST_SUCCESS, GET_UPLOAD_FS_VIDEO_ERROR, GET_UPLOAD_FS_VIDEO_LOADING, GET_UPLOAD_FS_VIDEO_SUCCESS } from "./get.types"

const initialState = {
    get_fs_loading: false,
    get_fs_error: false,
    get_fs_data: false,

    get_upload_fs_loading: false,
    get_upload_fs_error: false,
    get_upload_fs_data: [],

    get_fs_video_list_loading: false,
    get_fs_video_list_error: false,
    get_fs_video_list_data: [],

    get_fs_video_credits_loading: false,
    get_fs_video_credits_error: false,
    get_fs_video_credits_data: false
}


export const getFsReducer = (state = initialState, { type, payload }) => {

    switch (type) {

        case GET_UPLOAD_FS_VIDEO_LOADING: {

            return { ...state, get_upload_fs_loading: true }
        }

        case GET_UPLOAD_FS_VIDEO_SUCCESS: {

            return { ...state, get_upload_fs_loading: false, get_upload_fs_error: false, get_upload_fs_data: payload }
        }

        case GET_UPLOAD_FS_VIDEO_ERROR: {

            return { ...state, get_upload_fs_loading: false, get_upload_fs_error: true }
        }

        case GET_FS_VIDEO_JOB_LOADING: {

            return { ...state, get_fs_loading: true }
        }
        case GET_FS_VIDEO_JOB_SUCCESS: {

            return { ...state, get_fs_loading: false, get_fs_error: false, get_fs_data: payload }
        }
        case GET_FS_VIDEO_JOB_ERROR: {

            return { ...state, get_fs_loading: false, get_fs_error: true }
        }

        case GET_FS_VIDEOS_LIST_LOADING: {

            return { ...state, get_fs_video_list_loading: true }
        }
        case GET_FS_VIDEOS_LIST_SUCCESS: {
            return { ...state, get_fs_video_list_loading: false, get_fs_video_list_error: false, get_fs_video_list_data: payload }
        }
        case GET_FS_VIDEOS_LIST_ERROR: {

            return { ...state, get_fs_video_list_loading: false, get_fs_video_list_error: true }
        }

        case GET_FS_VIDEOS_CREDITS_LOADING: {

            return { ...state, get_fs_video_credits_loading: true }
        }
        case GET_FS_VIDEOS_CREDITS_SUCCESS: {

            return { ...state, get_fs_video_credits_loading: false, get_fs_video_credits_error: false, get_fs_video_credits_data: payload }
        }
        case GET_FS_VIDEOS_CREDITS_ERROR: {

            return { ...state, get_fs_video_credits_loading: false, get_fs_credits_list_error: true }
        }

        case RESET: {
            return initialState
        }
        default: {
            return state
        }
    }

}