import { RESET } from "../../document/doc.types"
import { PATCH_FS_VIDEO_ERROR, PATCH_FS_VIDEO_LOADING, PATCH_FS_VIDEO_SUCCESS, POST_FS_VIDEO_JOB_ERROR, POST_FS_VIDEO_JOB_LOADING, POST_FS_VIDEO_JOB_SUCCESS, POST_UPLOAD_FS_VIDEO_ERROR, POST_UPLOAD_FS_VIDEO_LOADING, POST_UPLOAD_FS_VIDEO_SUCCESS, SET_PATCH_FS_LOADING_FALSE } from "./create.type"

const initialState = {
    fs_upload_loading: false,
    fs_upload_error: false,

    fs_patch_loading: false,
    fs_patch_error: false,

    post_fs_job_loading:false,
    post_fs_job_error:false

}


export const fsCreateReducer = (state = initialState, { type, payload }) => {

    switch (type) {

        case POST_UPLOAD_FS_VIDEO_LOADING: {
            return { ...state, fs_upload_loading: true }
        }

        case POST_UPLOAD_FS_VIDEO_SUCCESS: {
            return { ...state, fs_upload_error: false, fs_upload_loading: false }
        }

        case POST_UPLOAD_FS_VIDEO_ERROR: {
            return { ...state, fs_upload_error: true, fs_upload_loading: false }
        }

        case PATCH_FS_VIDEO_LOADING: {

            return { ...state, fs_patch_loading: true }
        }

        case PATCH_FS_VIDEO_SUCCESS: {

            return { ...state, fs_patch_loading: false, fs_patch_error: false }
        }

        case PATCH_FS_VIDEO_ERROR: {

            return { ...state, fs_patch_loading: false, fs_patch_error: true }
        }

        case POST_FS_VIDEO_JOB_LOADING: {

            return { ...state, post_fs_job_loading: true }
        }

        case POST_FS_VIDEO_JOB_SUCCESS: {

            return {...state, post_fs_job_loading:false, post_fs_job_error:false}
        }

        case POST_FS_VIDEO_JOB_ERROR: {

            return {...state, post_fs_job_loading:false, post_fs_job_error:true}
        }

        case SET_PATCH_FS_LOADING_FALSE:{
            return {...state, fs_patch_loading:false}
        }


        case RESET: {
            return initialState
        }

        default: {
            return state
        }
    }

}