import { GET_PROFILE_ERROR, GET_PROFILE_LOADING, GET_PROFILE_SUCCESS } from "./profile.types"

const initialState = {
    profile_loading:false,
    profile_error:false,
    profile_data:false
}


export const profileReducer = (state = initialState, {payload, type}) => {

    switch(type) {

        case GET_PROFILE_LOADING:{
            return {...state, profile_loading:true}
        }
        
        case GET_PROFILE_SUCCESS:{

            return {...state, profile_loading:false, profile_error:false, profile_data:payload}
        }

        case GET_PROFILE_ERROR : {

            return {...state, profile_loading:false, profile_error:true}
        }

        default:{
            return state
        }
    }

}