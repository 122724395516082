import { RESET } from "../../document/doc.types"
import { DELETE_INSTANT_UPLOAD_ERROR, DELETE_INSTANT_UPLOAD_LOADING, DELETE_INSTANT_UPLOAD_SUCCESS, DELETE_INSTANT_VIDEO_ERROR, DELETE_INSTANT_VIDEO_LOADING, DELETE_INSTANT_VIDEO_SUCCESS } from "./delete.types"

const initialState = {
    delete_upload_loading: false,
    delete_upload_error: false,

    delete_video_loading: false,
    delete_video_error: false
}

export const deleteInstantReducer = (state = initialState, { type }) => {

    switch (type) {

        case DELETE_INSTANT_UPLOAD_LOADING: {

            return { ...state, delete_upload_loading: true }
        }
        case DELETE_INSTANT_UPLOAD_SUCCESS: {

            return { ...state, delete_upload_loading: false, delete_upload_error: false }
        }
        case DELETE_INSTANT_UPLOAD_ERROR: {

            return { ...state, delete_upload_loading: false, delete_upload_error: true }
        }

        case DELETE_INSTANT_VIDEO_LOADING: {

            return { ...state, delete_video_loading: true }
        }
        case DELETE_INSTANT_VIDEO_SUCCESS: {

            return { ...state, delete_video_loading: false, delete_video_error: false }
        }
        case DELETE_INSTANT_VIDEO_ERROR: {

            return { ...state, delete_video_loading: false, delete_video_error: true }
        }

        case RESET: {
            return initialState
        }

        default: {
            return state
        }
    }

}