export const GET = "get"
export const POST = "post"
export const PATCH = "patch"
export const DELETE = "delete"
export const X_ACCESS_TOKEN = "x-access-token"
export const CONTENT_TYPE = "Content-Type"
export const FORM_DATA = "form-data"
export const MULTIPART_FORM_DATA = "multipart/form-data"
export const MANAGER_BASE_URL = "https://manager.personate.ai"
export const ACCESS_TOKEN = "accessToken"
export const SUCCESS = "success"
export const ERROR = "error"
export const QUICKTIME = "quicktime"
export const MOV = "mov"
export const MPEG = "mpeg"
export const MP3 = "mp3"
export const VIDEO_MP4 = "video/mp4"
export const IMAGE_GIF = "image/gif"
export const IMAGE_SVG = "image/svg+xml"
export const AUDIO_MPEG = "audio/mpeg"
export const USER_DATA = "userData"
export const TRANS_STYLE = "transStyle"
export const LITERATION_STYLE = "literationStyle"
export const MAX_DURATION = 900
