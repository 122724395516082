import { RESET } from "../../document/doc.types"
import { GET_INSTANT_VIDEO_JOB_ERROR, GET_INSTANT_VIDEO_JOB_LOADING, GET_INSTANT_VIDEO_JOB_SUCCESS, GET_INSTANT_VIDEOS_CREDITS_ERROR, GET_INSTANT_VIDEOS_CREDITS_LOADING, GET_INSTANT_VIDEOS_CREDITS_SUCCESS, GET_INSTANT_VIDEOS_LIST_ERROR, GET_INSTANT_VIDEOS_LIST_LOADING, GET_INSTANT_VIDEOS_LIST_SUCCESS, GET_UPLOAD_VIDEO_ERROR, GET_UPLOAD_VIDEO_LOADING, GET_UPLOAD_VIDEO_SUCCESS } from "./get.types"

const initialState = {
    get_loading:false,
    get_error:false,
    get_data:false,

    get_upload_loading:false,
    get_upload_error:false,
    get_upload_data:[],

    get_video_list_loading:false,
    get_video_list_error:false,
    get_video_list_data:[],

    get_video_credits_loading:false,
    get_video_credits_error:false,
    get_video_credits_data:false
}

export const getInstantReducer = (state = initialState, {type, payload}) => {

    switch(type) {
        
        case GET_INSTANT_VIDEO_JOB_LOADING: {

            return {...state, get_loading:true}
        }
        case GET_INSTANT_VIDEO_JOB_SUCCESS: {

            return {...state, get_loading:false, get_error:false, get_data:payload}
        }
        case GET_INSTANT_VIDEO_JOB_ERROR: {

            return {...state, get_loading:false, get_error:true}
        }

        case GET_UPLOAD_VIDEO_LOADING: {

            return {...state, get_upload_loading:true}
        }
        case GET_UPLOAD_VIDEO_SUCCESS: {

            return {...state, get_upload_loading:false, get_upload_error:false, get_upload_data:payload}
        }
        case GET_UPLOAD_VIDEO_ERROR: {

            return {...state, get_upload_loading:false, get_upload_error:true}
        }

        case GET_INSTANT_VIDEOS_LIST_LOADING: {

            return {...state, get_video_list_loading:true}
        }
        case GET_INSTANT_VIDEOS_LIST_SUCCESS: {

            return {...state, get_video_list_loading:false, get_video_list_error:false, get_video_list_data:payload}
        }
        case GET_INSTANT_VIDEOS_LIST_ERROR: {

            return {...state, get_video_list_loading:false, get_video_list_error:true}
        }

        case GET_INSTANT_VIDEOS_CREDITS_LOADING: {

            return {...state, get_video_credits_loading:true}
        }
        case GET_INSTANT_VIDEOS_CREDITS_SUCCESS: {

            return {...state, get_video_credits_loading:false, get_video_credits_error:false, get_video_credits_data:payload}
        }
        case GET_INSTANT_VIDEOS_CREDITS_ERROR: {

            return {...state, get_video_credits_loading:false, get_credits_list_error:true}
        }

        case RESET: {
            return initialState
        }

        default: {
            return state
        }
    }

} 