import { GET_CREDITS_ERROR, GET_CREDITS_LOADING, GET_CREDITS_SUCCESS, RESET_CREDITS } from "./credits.types"

const initialState = {
    loading:false,
    error:false,
    credits:false
}

export const creditsReducer = (state=initialState, {type, payload}) => {

    switch(type) {

        case GET_CREDITS_LOADING : {

            return {...state, loading:true}
        }
        case GET_CREDITS_SUCCESS : {

            return {...state, loading:false, error:false, credits:payload}
        }
        case GET_CREDITS_ERROR : {

            return {...state, loading:false, error:true}
        }
        case RESET_CREDITS : {

            return initialState
        }

        default:{
            return state
        }
    }
}