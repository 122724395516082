import { RESET } from "../../document/doc.types"
import { PATCH_INSTANT_VIDEO_ERROR, PATCH_INSTANT_VIDEO_LOADING, PATCH_INSTANT_VIDEO_SUCCESS, POST_INSTANT_VIDEO_JOB_ERROR, POST_INSTANT_VIDEO_JOB_LOADING, POST_INSTANT_VIDEO_JOB_SUCCESS, POST_UPLOAD_VIDEO_ERROR, POST_UPLOAD_VIDEO_LOADING, POST_UPLOAD_VIDEO_SUCCESS, SET_PATCH_LOADING_FALSE } from "./create.types"

const initialState = {
    patch_loading: false,
    patch_error: false,
    post_loading:false,
    post_error:false,
    post_job_loading:false,
    post_job_error:false
}

export const createInstantReducer = (state = initialState, { type }) => {

    switch (type) {

        case PATCH_INSTANT_VIDEO_LOADING: {

            return { ...state, patch_loading: true }
        }

        case PATCH_INSTANT_VIDEO_SUCCESS: {

            return {...state, patch_loading:false, patch_error:false}
        }

        case PATCH_INSTANT_VIDEO_ERROR: {

            return {...state, patch_loading:false, patch_error:true}
        }

        case POST_UPLOAD_VIDEO_LOADING: {

            return { ...state, post_loading: true }
        }

        case POST_UPLOAD_VIDEO_SUCCESS: {

            return {...state, post_loading:false, post_error:false}
        }

        case POST_UPLOAD_VIDEO_ERROR: {

            return {...state, post_loading:false, post_error:true}
        }

        case POST_INSTANT_VIDEO_JOB_LOADING: {

            return { ...state, post_job_loading: true }
        }

        case POST_INSTANT_VIDEO_JOB_SUCCESS: {

            return {...state, post_job_loading:false, post_job_error:false}
        }

        case POST_INSTANT_VIDEO_JOB_ERROR: {

            return {...state, post_job_loading:false, post_job_error:true}
        }

        case SET_PATCH_LOADING_FALSE:{
            return {...state, patch_loading:false}
        }

        case RESET: {
            return initialState
        }

        default: {
            return state
        }
    }
}