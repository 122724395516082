import { RESET } from "../../document/doc.types"
import { SET_AUDIO_LINK_AG_INTERNAL, SET_CLONE_AUDIO_AG_INTERNAL, SET_DOWNLOAD_AUDIO_AG_INTERNAL, SET_LANGUAGE_AG_INTERNAL, SET_TEXT_AG_INTERNAL, SET_VOICE_AG_INTERNAL } from "./ag.types"

const initialState = {
    audio_link:"",
    text:"",
    language:"",
    voice:"",
    clone_link:"",
    download_link:""
}

export const internalAgReducer = (state = initialState, {type, payload}) => {

    switch(type) {
        
        case SET_TEXT_AG_INTERNAL: {
            return {...state, text:payload}
        }
        case SET_VOICE_AG_INTERNAL: {
            return {...state, voice:payload}
        }
        case SET_AUDIO_LINK_AG_INTERNAL: {
            return {...state, audio_link:payload}
        }
        case SET_LANGUAGE_AG_INTERNAL: {
            return {...state, language:payload}
        }
        case SET_CLONE_AUDIO_AG_INTERNAL: {
            return {...state, clone_link:payload}
        }

        case SET_DOWNLOAD_AUDIO_AG_INTERNAL: {
            return {...state, download_link:payload}
        }

        case RESET: {
            return initialState
        }
        default: {
            return state
        }
    }
}