import { ACCESS_TOKEN, USER_DATA } from "../../scripts/utils/constants";
import { GET_VERIFY_TOKEN, REMOVE_LOGIN_DATA, SET_LOGIN_DATA } from "./login.types";

const initialData = {
    userData: JSON.parse(localStorage.getItem(USER_DATA)) || null,
    accessToken: localStorage.getItem(ACCESS_TOKEN) || null
}

export const loginReducer = (state = initialData, { type, payload }) => {

    switch (type) {
        case SET_LOGIN_DATA: {
            return { ...state, userData: payload.userData, accessToken: payload.accessToken }
        }

        case REMOVE_LOGIN_DATA: {
            localStorage.removeItem(USER_DATA)
            localStorage.removeItem(ACCESS_TOKEN)
            return { ...state, userData: null, accessToken: null }
        }
        
        case GET_VERIFY_TOKEN : {
            if (!payload) {
                return { ...state, userData: null, accessToken: null }
                
            } else {
                return state
            }
        }
        default: {
            return state
        }
    }
}