export const GET_UPLOAD_FS_VIDEO_LOADING = "get/upload/fs/video/loading"
export const GET_UPLOAD_FS_VIDEO_SUCCESS = "get/upload/fs/video/success"
export const GET_UPLOAD_FS_VIDEO_ERROR = "get/upload/fs/video/error"

export const GET_FS_VIDEO_JOB_LOADING = "get/fs/video/job/loading"
export const GET_FS_VIDEO_JOB_SUCCESS = "get/fs/video/job/success"
export const GET_FS_VIDEO_JOB_ERROR = "get/fs/video/job/error"

export const GET_FS_VIDEOS_LIST_LOADING = "get/fs/videos/list/loading"
export const GET_FS_VIDEOS_LIST_ERROR = "get/fs/videos/list/error"
export const GET_FS_VIDEOS_LIST_SUCCESS = "get/fs/videos/list/success"

export const GET_FS_VIDEOS_CREDITS_LOADING = "get/fs/videos/credits/loading"
export const GET_FS_VIDEOS_CREDITS_ERROR = "get/fs/videos/credits/error"
export const GET_FS_VIDEOS_CREDITS_SUCCESS = "get/fs/videos/credits/success"