export const PATCH_INSTANT_VIDEO_LOADING = "patch/instant/video/loading"
export const PATCH_INSTANT_VIDEO_SUCCESS = "patch/instant/video/success"
export const PATCH_INSTANT_VIDEO_ERROR = "patch/instant/video/error"

export const POST_UPLOAD_VIDEO_LOADING = "post/upload/video/loading"
export const POST_UPLOAD_VIDEO_SUCCESS = "post/upload/video/success"
export const POST_UPLOAD_VIDEO_ERROR = "post/upload/video/error"

export const POST_INSTANT_VIDEO_JOB_LOADING = "post/instant/video/job/loading"
export const POST_INSTANT_VIDEO_JOB_SUCCESS = "post/instant/video/job/success"
export const POST_INSTANT_VIDEO_JOB_ERROR = "post/instant/video/job/error"

export const SET_PATCH_LOADING_FALSE = "set/patch/loading/false"
