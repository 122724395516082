import { DISSMISS_LOADER, GET_TRANS_JOBID_ERROR, GET_TRANS_JOBID_LOADING, GET_TRANS_JOBID_SUCCESS, GET_TRANS_JOB_STATUS_ERROR, GET_TRANS_JOB_STATUS_LOADING, GET_TRANS_JOB_STATUS_SUCCESS, PATCH_TRANSCRIPT_ERROR, PATCH_TRANSCRIPT_LOADING, PATCH_TRANSCRIPT_LOADING_FALSE, PATCH_TRANSCRIPT_SUCCESS, POST_TRANSCRIPT_JOB_ERROR, POST_TRANSCRIPT_JOB_LOADING, POST_TRANSCRIPT_JOB_SUCCESS, RESET, SET_DOC_ID } from "./trans.types"

const initialState = {
    job_loading: false,
    job_error: false,
    job_id: null,
    job_data: null,
    job_status_loading: false,
    job_status_error: false,
    trans_loading:false,
    trans_error:false,
    trans_data:null,
    trans_job_loading:false,
    trans_job_error:false,
    trans_job_submitted:false,
    doc_id:""
}

export const transReducer = (state = initialState, { type, payload }) => {

    switch (type) {

        case GET_TRANS_JOBID_LOADING: {
            return { ...state, job_loading: true }
        }

        case GET_TRANS_JOBID_SUCCESS: {
            return { ...state, job_loading: false, job_error: false, job_id: payload }
        }

        case GET_TRANS_JOBID_ERROR: {
            return { ...state, job_error: true, job_loading: false }
        }


        case GET_TRANS_JOB_STATUS_LOADING: {
            return { ...state, job_status_loading: true }
        }

        case GET_TRANS_JOB_STATUS_SUCCESS: {
            return { ...state, job_status_error: false, job_data: payload }
        }

        case GET_TRANS_JOB_STATUS_ERROR: {
            return { ...state, job_status_error: true, job_status_loading: false }
        }

        case PATCH_TRANSCRIPT_LOADING: {
            return { ...state, trans_loading: true }
        }
        case PATCH_TRANSCRIPT_LOADING_FALSE: {
            return { ...state, trans_loading: false }
        }

        case PATCH_TRANSCRIPT_SUCCESS: {
            return { ...state, trans_error: false, trans_data: payload, trans_loading:false }
        }

        case PATCH_TRANSCRIPT_ERROR: {
            return { ...state, trans_error: true, trans_loading: false }
        }

        case POST_TRANSCRIPT_JOB_LOADING: {
            return { ...state, trans_job_loading: true }
        }

        case POST_TRANSCRIPT_JOB_SUCCESS: {
            return { ...state, trans_job_error: false, trans_job_submitted: payload, trans_job_loading:false }
        }

        case POST_TRANSCRIPT_JOB_ERROR: {
            return { ...state, trans_job_error: true, trans_job_loading: false }
        }

        case DISSMISS_LOADER: {

            return { ...state, job_status_loading: false }
        }
        case SET_DOC_ID: {

            return { ...state, doc_id:payload }
        }

        
        case RESET: {

            return initialState

        }

        default: {
            return state
        }
    }

}