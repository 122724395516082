export const POST_VOICES_LIST_LOADING = "post/voices/list/loading"
export const POST_VOICES_LIST_SUCCESS = "post/voices/list/success"
export const POST_VOICES_LIST_ERROR = "post/voices/list/error"

export const POST_AUDIO_TO_TEXT_LOADING = "post/audio/to/text/loading"
export const POST_AUDIO_TO_TEXT_SUCCESS = "post/audio/to/text/success"
export const POST_AUDIO_TO_TEXT_ERROR = "post/audio/to/text/error"

export const POST_AUDIO_TO_AUDIO_LOADING = "post/audio/to/audio/loading"
export const POST_AUDIO_TO_AUDIO_SUCCESS = "post/audio/to/audio/success"
export const POST_AUDIO_TO_AUDIO_ERROR = "post/audio/to/audio/error"

export const POST_CLONE_AUDIO_LOADING = "post/clone/audio/loading"
export const POST_CLONE_AUDIO_SUCCESS = "post/clone/audio/success"
export const POST_CLONE_AUDIO_ERROR = "post/clone/audio/error"