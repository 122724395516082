import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';
import FallbackLoader from './shared/components/FallbackLoader';
const root = ReactDOM.createRoot(document.getElementById('root'));
const theme = extendTheme({
  colors: {
    brand: "#9e3df2"
  },
  fonts: {
    body: `Montserrat`,
  },

})

const LazyApp = lazy(() => import('./App'))
root.render(
  <ChakraProvider theme={theme}>
    <BrowserRouter >
      <Provider store={store}>
        <Suspense fallback={<FallbackLoader />} >
          <LazyApp />
        </Suspense>
      </Provider>
    </BrowserRouter>
  </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
