import { applyMiddleware, combineReducers, legacy_createStore } from "redux";
import {docReducer} from "../redux/document/doc.reducer"
import {thunk} from "redux-thunk";
import { transReducer } from "./transcription/trans.reducer";
import { loginReducer } from "./login/login.reducer";
import { creditsReducer } from "./credits/credits.reducer";
import { voicesReducer } from "./voices/voices.reducer";
import { profileReducer } from "./profile/profile.reducer";
import { utilsReducer } from "./utils/utils.reducer";
import { createInstantReducer } from "./instantVideoReduxFiles/create/create.reducer";
import { getInstantReducer } from "./instantVideoReduxFiles/get/get.reducer";
import { deleteInstantReducer } from "./instantVideoReduxFiles/delete/delete.reducer";
import { fsCreateReducer } from "./face-swap-redux-files/create/create.reducer";
import { getFsReducer } from "./face-swap-redux-files/get/get.reducer";
import { deleteFsReducer } from "./face-swap-redux-files/delete/delete.reducer";
import { postAgReducer } from "./audio-generation-redux-files/post/post.reducer";
import { internalAgReducer } from "./audio-generation-redux-files/internal/ag.reducer";



const rootReducer = combineReducers({
    docReducer,
    transReducer,
    loginReducer,
    creditsReducer,
    voicesReducer,
    profileReducer,
    utilsReducer,
    createInstantReducer, 
    getInstantReducer, 
    deleteInstantReducer,
    fsCreateReducer,
    getFsReducer,
    deleteFsReducer,
    postAgReducer, 
    internalAgReducer
})

const store = legacy_createStore(rootReducer, applyMiddleware(thunk))


export default store
