import { RESET } from "../../document/doc.types"
import { DELETE_FS_UPLOAD_ERROR, DELETE_FS_UPLOAD_LOADING, DELETE_FS_UPLOAD_SUCCESS, DELETE_FS_VIDEO_ERROR, DELETE_FS_VIDEO_LOADING, DELETE_FS_VIDEO_SUCCESS } from "./delete.types"

const initialState = {
    delete_fs_upload_loading: false,
    delete_fs_upload_error: false,

    delete_fs_video_loading: false,
    delete_fs_video_error: false
}


export const deleteFsReducer = (state = initialState, { type }) => {

    switch (type) {

        case DELETE_FS_UPLOAD_LOADING: {

            return { ...state, delete_fs_upload_loading: true }
        }
        case DELETE_FS_UPLOAD_SUCCESS: {

            return { ...state, delete_fs_upload_loading: false, delete_fs_upload_error: false }
        }
        case DELETE_FS_UPLOAD_ERROR: {

            return { ...state, delete_fs_upload_loading: false, delete_fs_upload_error: true }
        }

        case DELETE_FS_VIDEO_LOADING: {

            return { ...state, delete_fs_video_loading: true }
        }
        case DELETE_FS_VIDEO_SUCCESS: {

            return { ...state, delete_fs_video_loading: false, delete_fs_video_error: false }
        }
        case DELETE_FS_VIDEO_ERROR: {

            return { ...state, delete_fs_video_loading: false, delete_fs_video_error: true }
        }

        case RESET: {
            return initialState
        }

        default: {
            return state
        }
    }

}