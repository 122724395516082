import { RESET } from "../../document/doc.types"
import { POST_AUDIO_TO_AUDIO_ERROR, POST_AUDIO_TO_AUDIO_LOADING, POST_AUDIO_TO_AUDIO_SUCCESS, POST_AUDIO_TO_TEXT_ERROR, POST_AUDIO_TO_TEXT_LOADING, POST_AUDIO_TO_TEXT_SUCCESS, POST_CLONE_AUDIO_ERROR, POST_CLONE_AUDIO_LOADING, POST_CLONE_AUDIO_SUCCESS, POST_VOICES_LIST_LOADING, POST_VOICES_LIST_SUCCESS } from "./post.types"

const initialState = {
    voices_loading: false,
    voices_error: false,
    voices_data: false,

    at_loading: false,
    at_error: false,
    at_data: false,

    aa_loading: false,
    aa_error: false,
    aa_data: false,

    ca_loading:false,
    ca_error:false
}


export const postAgReducer = (state = initialState, { type, payload }) => {

    switch (type) {

        case POST_VOICES_LIST_LOADING: {

            return { ...state, voices_loading: true }
        }
        case POST_VOICES_LIST_SUCCESS: {

            return { ...state, voices_loading: false, voices_error: false, voices_data: payload }
        }
        case POST_VOICES_LIST_LOADING: {

            return { ...state, voices_loading: false, voices_error: true }
        }

        case POST_AUDIO_TO_TEXT_LOADING: {

            return { ...state, at_loading: true }
        }
        case POST_AUDIO_TO_TEXT_SUCCESS: {

            return { ...state, at_loading: false, at_error: false, at_data: payload }
        }
        case POST_AUDIO_TO_TEXT_ERROR: {

            return { ...state, at_loading: false, at_error: true }
        }

        case POST_AUDIO_TO_AUDIO_LOADING: {

            return { ...state, aa_loading: true }
        }
        case POST_AUDIO_TO_AUDIO_SUCCESS: {

            return { ...state, aa_loading: false, aa_error: false, aa_data: payload }
        }
        case POST_AUDIO_TO_AUDIO_ERROR: {

            return { ...state, aa_loading: false, aa_error: true }
        }

        case POST_CLONE_AUDIO_LOADING: {

            return { ...state, ca_loading: true }
        }
        case POST_CLONE_AUDIO_SUCCESS: {

            return { ...state, ca_loading: false, ca_error: false }
        }
        case POST_CLONE_AUDIO_ERROR: {

            return { ...state, ca_loading: false, ca_error: true }
        }

        

        case RESET: {
            return initialState
        }
        default: {
            return state
        }
    }
}