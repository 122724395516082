export const GET_INSTANT_VIDEO_JOB_LOADING = "get/instant/video/job/loading"
export const GET_INSTANT_VIDEO_JOB_SUCCESS = "get/instant/video/job/success"
export const GET_INSTANT_VIDEO_JOB_ERROR = "get/instant/video/job/error"

export const GET_UPLOAD_VIDEO_LOADING = "get/upload/video/loading"
export const GET_UPLOAD_VIDEO_SUCCESS = "get/upload/video/success"
export const GET_UPLOAD_VIDEO_ERROR = "get/upload/video/error"

export const GET_INSTANT_VIDEOS_LIST_LOADING = "get/instant/videos/list/loading"
export const GET_INSTANT_VIDEOS_LIST_SUCCESS = "get/instant/videos/list/success"
export const GET_INSTANT_VIDEOS_LIST_ERROR = "get/instant/videos/list/error"

export const GET_INSTANT_VIDEOS_CREDITS_LOADING = "get/instant/videos/credits/loading"
export const GET_INSTANT_VIDEOS_CREDITS_SUCCESS = "get/instant/videos/credits/success"
export const GET_INSTANT_VIDEOS_CREDITS_ERROR = "get/instant/videos/credits/error"

